import React, { useState, useEffect } from "react";

// Images
import { ReactComponent as TeamScore } from "../assets/SVG/teamScore.svg";

// CSS
import "../assets/css/left-bar.css";

// Components
import InvestedDroppableField from "./Droppables/InvestedDroppableField";
import Translate from "../utils/Translate";
import { useSelector } from "react-redux";


const LeftBar = ({
	canEdit,
	stateData,
	teamsData,
	teamsKeys,
	relationsData,
	activeCustomerCardIds,
	timeCoinsImpact,
	moneyCoinsImpact,
	scoreData
}) => {
	const [teamScore, setTeamScore] = useState(0);
	const [teamsInvestedKeys, setTeamsInvestedKeys] = useState([]);

	const sessionState = useSelector(state => state.session)
	const teamId = window.teamId
	let correctTeamScore

	useEffect(()=>{
		if (sessionState) {
			let teamNewScoreArray = sessionState.centralBoardData.filter((teamData)=> teamData.teamId == teamId)
			if(teamNewScoreArray.length){
				setTeamScore(teamNewScoreArray[0].teamScore)
			}
		}

	},[sessionState])


	useEffect(() => {
		let sumCompetence = 0;
		let sumSatisfaction = 0;
		let sumBelongingness = 0;
		let sumAwarenessOfRole = 0;
		let sumTimeLeft = 0;
		let sumBudgetLeft = 0;
		let sumRelation = 0;
		teamsKeys.map((team) => {
			sumCompetence += teamsData[team].competence;
			sumSatisfaction += teamsData[team].satisfaction;
			sumBelongingness += teamsData[team].belongingness;
			sumAwarenessOfRole += teamsData[team].awarenessOfRole;
			sumTimeLeft += teamsData[team].time.coins;
			sumBudgetLeft += teamsData[team].budget.coins;
		});
		Object.keys(relationsData).map(
			(x) => (sumRelation += relationsData[x])
		);

		const marketingScore = stateData.Marketing.budget * 3
		const reputationScore = scoreData.reputation * 3 //5

		let teamScore = sumCompetence * 1.5 //2
		teamScore += sumSatisfaction * 1.5 //2
		teamScore += sumRelation * 2
		teamScore += sumBelongingness * 2 //5
		teamScore += sumAwarenessOfRole * 2 //5
		teamScore += sumTimeLeft * 0.5
		teamScore += sumBudgetLeft * 0.5
		teamScore += marketingScore
		teamScore += reputationScore
		
		setTeamScore(teamScore);
	}, [teamsData, relationsData, stateData, scoreData]);

	useEffect(() => {
		if (stateData) {
			setTeamsInvestedKeys(Object.keys(stateData));
			console.log();
		}
	}, [stateData]);
	return (
		<div
			className="left-bar"
			style={
				activeCustomerCardIds
					? { paddingTop: "17%" }
					: { paddingTop: "130px" }
			}
		>
			<div>
				<div className="teamscore">
					<span>
						<Translate alias="Board" word="TEAMSCORE" />
					</span>
					<div style={{ position: "relative" }}>
						<span className="team-score-value">{ teamScore ? teamScore : 0 }</span>
						<TeamScore className="team-score-badge" />
					</div>
				</div>
			</div>
			<div
				className="invested"
				style={{ marginTop: "130px", marginBottom: "20px" }}
			>
				<div className="teamscore">
					<span style={{ marginLeft: "-13px" }}>
						<Translate alias="Board" word="INVESTED" />
					</span>
				</div>
				{teamsInvestedKeys.map((team, index) => (
					<div key={index} className="side-bar-block">
						<InvestedDroppableField
							canEdit={canEdit}
							alias={"Invested"}
							name={team}
							data={stateData[team]}
							timeCoinsImpact={timeCoinsImpact}
							moneyCoinsImpact={moneyCoinsImpact}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default LeftBar;
