import React, { useState } from "react";
import { useDrag, DragPreviewImage, useDrop } from "react-dnd";
import { Image } from "react-bootstrap";
import { Modal, ModalHeader } from "reactstrap";

// Constants
import ITEMS from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Components
import DraggableCoin from "./DraggableCoin";

// Images
import previewImg from "../../assets/images/draggable-container-preview.png";
import horizontalContainerImg from "../../assets/images/container-h.png";
import verticalContainerImg from "../../assets/images/container-v.png";
import ContainerModal from "./ContainerModal";
import Translate from "../../utils/Translate";
const DraggableContainer = ({
	canEdit,
	coins,
	isHorizontal,
	alias,
	name,
	type,
}) => {
	const [{ isDragging }, drag, preview] = useDrag({
		type: ITEMS.droppedContainer,
		item: {
			type: type,
			coins: coins,
			alias: alias,
			name: name,
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						alias: alias,
						name: name,
						type: type,
					};
					RealtimeConnection.removeContainer(obj);
				}
			}
		},
	});

	const [{ containerId }, drop] = useDrop({
		accept: type === "time" ? ITEMS.time : ITEMS.coin,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			containerId: monitor.getHandlerId(),
		}),
		drop: (item, monitor) => {
			if (monitor.getHandlerId() === item.containerId) {
				return {};
			}
			console.log(item);
			console.log("Should Add Coin");
			const checkAgainstArray = [];
			if (Array.isArray(item.name)) {
				checkAgainstArray.push(...item.name);
			} else {
				checkAgainstArray.push(item.name);
			}
			if (
				item.coinType === type &&
				checkAgainstArray.includes(name) &&
				coins < 20
			) {
				RealtimeConnection.addCoin({
					alias: alias,
					name: name,
					type: type,
					coins: item.value,
				});
				return { success: true };
			}
			if (
				item.coinType === "budget" &&
				item.name === "General" &&
				name === "TeamLeader" &&
				coins < 20
			) {
				RealtimeConnection.addCoin({
					alias: alias,
					name: name,
					type: type,
					coins: item.value,
				});
				return { success: true };
			}
		},
	});

	const [modal, setModal] = useState(false);
	const [isShown, setIsShown] = useState(true);

	const toggle = (e) => {
		e.stopPropagation();
		setModal(!modal);
	};

	const coinsContainerClass = isHorizontal
		? "Coins-droppable"
		: "Coins-vertical-droppable";
	const containerImg = isHorizontal
		? horizontalContainerImg
		: verticalContainerImg;

	let canPlayerDrag = canEdit ? drag : null;
	let canPlayerDrop = canEdit ? drop : null;

	let dragClass = "";
	//dragClass = "dragging";
	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div
				className={`containerObjClass ${dragClass}`}
				ref={canPlayerDrag}
				onClick={toggle}
			>
				<div
					className="coins-sum"
					style={
						isShown && coins
							? { display: "block" }
							: { display: "none" }
					}
				>
					<p style={{ margin: 0 }}>{coins}</p>
				</div>
				<Image
					className="objModelClass"
					src={containerImg}
					style={{ width: "100%" }}
					draggable={false}
				/>
				<div
					className={coinsContainerClass}
					ref={canPlayerDrop}
					// onMouseEnter={() => setIsShown(true)}
					// onMouseLeave={() => setIsShown(false)}
				>
					{isHorizontal && <span className="helper"></span>}
					{coins
						? Array(coins)
								.fill(type === "time" ? 1 : 10)
								.map((value, index) => (
									<DraggableCoin
										containerId={containerId}
										canEdit={canEdit}
										key={index}
										value={value}
										isLastCoin={
											coins === index + 1 &&
											(index + 1) % 5 === 0
												? true
												: false
										}
										isHorizontal={isHorizontal}
										alias={alias}
										name={name}
										type={type}
									/>
								))
						: null}
				</div>
				<Modal
					backdrop="static"
					className="ContainerPropertiesPopup"
					isOpen={modal}
					toggle={toggle}
				>
					<ModalHeader className="mb-4" toggle={toggle} >
						<Translate alias={'Board'} word={'remaining coins'}/>
					</ModalHeader>
					<ContainerModal
						coins={coins}
						type={type}
						name={name}
						alias={alias} 
						setIsShown={setModal}
					/>
				</Modal>
			</div>
		</>
	);
};

export default DraggableContainer;
