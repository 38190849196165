import React, { useEffect, useState } from "react";

// Components
import DroppableField from "../Droppables/DroppableField";
import Profile from "./Profile";

import MainDraggableContainer from "../Draggables/MainDraggableContainer"

// Images
import { ReactComponent as CompetenceSVG } from "../../assets/SVG/competence.svg";
import { ReactComponent as Satisfaction } from "../../assets/SVG/satisfaction.svg";
import HappyFace from "../../assets/images/HappyFace.png";
import SadFace from "../../assets/images/SadFace.png";
import Translate from "../../utils/Translate";

const SingleCircle = ({
	canEdit,
	name,
	stateData,
	impactData,
	color,
	top,
	right,
	timeCoinsImpact,
	moneyCoinsImpact
}) => {
	const [openProfile, setOpenProfile] = useState(false);

	const [isWhite, setIsWhite] = useState(false);
	const [isGrey, setIsGrey] = useState(false);

	useEffect(() => {
		if (name === "TeamLeader") {
			setIsGrey(true);
		}
		if (name === "White") {
			setIsWhite(true);
		}
	}, [name]);

	const styles = () => {
		let styles = {
			top: `${top}px`,
			right: `${right}px`,
			backgroundColor: `${color}`,
			color: `${color}`,
		};
		if (isWhite) {
			styles.backgroundColor = "white";
		}
		if (isGrey) {
			styles.width = "254px";
			styles.height = "241px";
		}

		return styles;
	};

	const ringStyle = (y) => {
		return {
			borderColor: `${color}`,
			background: `${color}`,
			marginTop: y * -3,
			right: y * -10,
		};
	};
	const handleProfile = (e) => {
		e.preventDefault();
		setOpenProfile(true);
	};

	return (
		<React.Fragment>
			<div
				className="circle"
				style={styles()}
				onClick={(e) => handleProfile(e)}
			>
				<div>
					<div
						className="rectangle"
						style={isWhite ? { border: "1px solid #899196" } : {}}
					>
						<div className="flex-column">
							<span style={{ maxWidth: 65 }}>
								<Translate alias="Board" word="Awareness of role / function" />
								{
									(impactData.awarenessOfRole !== 0) ?
										<span
											className={ `impact-visual-dots ${ impactData.awarenessOfRole > 0 ? "green" : "red" }` }
										>
											{ impactData.awarenessOfRole > 0 ? "+" : "" }{ impactData.awarenessOfRole }
										</span>
										:
										null
								}
							</span>
							{ /*<span style={{ marginTop: "-2px" }}>
								<Translate alias="Board" word="function" />
							</span>*/ }
						</div>
						<div className="rings">
							{[...Array(5)].map((_, y) => (
								<div key={y}>
									<div
										className={`ring ${ stateData.awarenessOfRole <= y && "checked" }`}
										style={ringStyle(y)}
									/>
								</div>
							))}
						</div>
					</div>

					<div
						className="rectangle"
						style={isWhite ? { border: "1px solid #899196" } : {}}
					>
						<div>
							<span>
								<Translate alias="Board" word="Belongingness" />
								{ 
									(impactData.belongingness !== 0) ?
										<span
											className={ `ml-2 impact-visual-dots ${ impactData.belongingness > 0 ? "green" : "red" }` }
										>
											{ impactData.belongingness > 0 ? "+" : "" }{ impactData.belongingness }
										</span>
										:
										null
								}
							</span>
						</div>
						<div className="rings">
							{[...Array(5)].map((_, y) => (
								<div key={y}>
									<div
										className={`ring ${
											stateData.belongingness <= y &&
											"checked"
										}`}
										style={ringStyle(y)}
									/>
								</div>
							))}
						</div>
					</div>
					<div
						className="three-bars"
						style={isGrey ? { width: "202px" } : {}}
					>
						<div style={isWhite ? { color: color } : null}>
							<div style={ isWhite ? { border: `1px solid ${color}` } : null } >
								<span style={{ fontSize: "24px", color: color }} >
									{ 
										(impactData.competence !== 0) ? 
											<span
												className={
													impactData.competence > 0 ? "impact-increase" : "impact-decrease"
												}
											>
												{ impactData.competence > 0 ? "+" : "" }{ impactData.competence }
											</span>
											:
											null
									}
									{ stateData.competence }
								</span>
								<div className={`svgContainer ${name}`}>
									<CompetenceSVG />
								</div>
							</div>
							<img
								src={ stateData.competence >= 0 ? HappyFace : SadFace }
								alt=""
								className="happy-face"
							/>
							<span><Translate alias="Board" word="Competence" /></span>
						</div>
						<div style={isWhite ? { color: color } : null}>
							<div style={ isWhite ? { border: `1px solid ${color}` } : null }>
								<span style={{ fontSize: "24px", color: color }} >
									{ 
										(impactData.satisfaction !== 0) ?
											<span
												className={
													impactData.satisfaction > 0
														? "impact-increase" : "impact-decrease"
												}
											>
												{ impactData.satisfaction > 0 ? "+" : "" }{ impactData.satisfaction }
											</span>
											:
											null
									}
									{ stateData.satisfaction }
								</span>
								<div className={`svgContainer ${name}`}>
									<Satisfaction />
								</div>
							</div>
							<img
								src={ stateData.satisfaction >= 0 ? HappyFace : SadFace }
								alt=""
								className="happy-face"
							/>
							<span>
								<Translate alias="Board" word="Satisfaction" />
							</span>
						</div>
						<div style={isWhite ? { color: color } : null}>
							<div
								style={
									isWhite
										? { border: `1px solid ${color}` }
										: null
								}
							>
								<DroppableField
									teamContainer={true}
									canEdit={canEdit}
									alias={"Teams"}
									name={name}
									type={"time"}
									data={stateData.time}
									timeCoinsImpact={timeCoinsImpact}
									moneyCoinsImpact={moneyCoinsImpact}
								/>
							</div>
							<span>
								<Translate alias="Board" word="Time" />
							</span>
						</div>
						{isGrey && (
							<div>
								<div style={{ border: `1px solid ${color}` }}>
									<DroppableField
										teamContainer={true}
										canEdit={canEdit}
										alias={"Teams"}
										name={name}
										type={"budget"}
										data={stateData.budget}
										timeCoinsImpact={timeCoinsImpact}
										moneyCoinsImpact={moneyCoinsImpact}
									/>
								</div>
								<span>
									<Translate alias="Board" word="Budget" />
								</span>
							</div>
						)}
					</div>
				</div>
			</div>

			{openProfile && (
				<Profile
					name={name}
					closeProfile={() => setOpenProfile(false)}
				/>
			)}
		</React.Fragment>
	);
};

export default SingleCircle;
