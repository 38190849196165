import React, { useEffect, useState } from "react";

import MainLogo from "../../assets/images/mainLogo.png";
import SadFace from "../../assets/images/SadFace.png";
import HappyFace from "../../assets/images/HappyFace.png";
import RealtimeConnection from "../../utils/RealtimeConnection";

export default function ProbabilityCard({
	cardStatus,
	index,
	eventId,
	selectedOption,
	probabilityCard,
}) {
	const [image, setImage] = useState(MainLogo);
	const [isClicked, setIsClicked] = useState("");
	const [showOther, setShowOther] = useState("");
	const [disabled, setDisabled] = useState("");

	useEffect(() => {
		if (isClicked) {
			cardStatus && setImage(HappyFace);
			!cardStatus && setImage(SadFace);
		}
	}, [isClicked, selectedOption]);

	useEffect(() => {
		if (probabilityCard) {
			cardStatus && setImage(HappyFace);
			!cardStatus && setImage(SadFace);
			setIsClicked("clicked");
		}
	}, [probabilityCard]);

	useEffect(() => {
		if (probabilityCard) {
			setDisabled("disabled");
			if (probabilityCard.index === index) {
				setShowOther("show");
			}
		}
	}, [probabilityCard]);

	const selectCard = () => {
		if (probabilityCard === undefined) {
			setIsClicked("clicked");
			setShowOther("show");
			RealtimeConnection.probabilityCard({
				eventId,
				status: cardStatus ? "happy" : "sad",
				index: index
			});
		}
	};

	return (
		<div
			className={`probability-card ${isClicked} ${showOther} ${disabled} `}
			onClick={() => selectCard()}
		>
			<img src={image} alt="" />
			{index + 1}
		</div>
	);
}
