import React, { useState } from "react";
import { useDrag, DragPreviewImage, useDrop } from "react-dnd";
import { Image } from "react-bootstrap";
import { Modal, ModalHeader } from "reactstrap";

// Constants
import ITEMS from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Components
import DraggableCoin from "./DraggableCoin";

// Images
import previewImg from "../../assets/images/draggable-container-preview.png";
import horizontalContainerImg from "../../assets/images/container-h.png";
import verticalContainerImg from "../../assets/images/container-v.png";
import ContainerModal from "./ContainerModal";
import Translate from "../../utils/Translate";

const InvestedDraggableContainer = ({
	canEdit,
	time,
	budget,
	isHorizontal,
	alias,
	name,
}) => {
	const [{ isDragging }, drag, preview] = useDrag({
		type: ITEMS.container,
		item: {
			type: budget ? "budget" : "time",
			time: time,
			budget: budget,
			alias: alias,
			name: name,
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (item, monitor) => {
			if (monitor.didDrop()) {
				let droppedItem = monitor.getDropResult();
				if (droppedItem !== null && droppedItem.success) {
					const obj = {
						alias: alias,
						name: name,
						type: budget ? "budget" : "time",
					};
					RealtimeConnection.removeContainer(obj);
				}
			}
		},
	});

	const [modal, setModal] = useState(false);
	const [isShown, setIsShown] = useState(true);

	const toggle = (e) => {
		e.stopPropagation();
		setModal(!modal);
	};

	const [{ containerId }, drop] = useDrop({
		accept: name === "General" || name === "Marketing" ? ITEMS.coin : [ITEMS.coin, ITEMS.time],
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			containerId: monitor.getHandlerId(),
		}),
		drop: (item, monitor) => {
			if (monitor.getHandlerId() === item.containerId) {
				return {};
			}

			console.log(item);

			if (time < 20) {
				RealtimeConnection.addCoin({
					alias: alias,
					name: name,
					type: item.coinType,
					coins: item.value,
				});
				return { success: true };
			}
		},
	});

	const coinsContainerClass = isHorizontal
		? "Coins-droppable"
		: "Coins-vertical-droppable";
	const containerImg = isHorizontal
		? horizontalContainerImg
		: verticalContainerImg;

	let canPlayerDrag = canEdit ? drag : null;
	let canPlayerDrop = canEdit ? drop : null;

	let dragClass = "";
	//dragClass = "dragging";

	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div
				className={`containerObjClass ${dragClass}`}
				ref={canPlayerDrag}
				onClick={toggle}
			>
				<div
					className="coins-sum"
					style={
						isShown && (time || budget)
							? { display: "block" }
							: { display: "none" }
					}
				>
					{
						(name !== "General" && name !== "Marketing") ?
							<p style={{ color: "#5bc8f5", margin: 0 }}>{time}</p>
							:
							null
					}
					<p style={{ color: "#d1d1d1", margin: 0 }}>{budget}</p>
				</div>
				<Image
					className="objModelClass"
					src={containerImg}
					style={{ width: "100%" }}
					draggable={ false }
				/>
				<div
					className={coinsContainerClass}
					ref={canPlayerDrop}
					// onMouseEnter={() => setIsShown(true)}
					// onMouseLeave={() => setIsShown(false)}
				>
					{isHorizontal && <span className="helper"></span>}
					{budget
						? Array(budget)
								.fill(10)
								.map((value, index) => (
									<DraggableCoin
										containerId={containerId}
										canEdit={canEdit}
										key={index}
										value={value}
										isLastCoin={
											budget + time === index + 1 &&
											(index + 1) % 5 === 0
												? true
												: false
										}
										isHorizontal={isHorizontal}
										alias={alias}
										name={name}
										type={"budget"}
										coinType={ITEMS.coin}
									/>
								))
						: null}
					{time
						? Array(time)
								.fill(1)
								.map((value, index) => (
									<DraggableCoin
										containerId={containerId}
										canEdit={canEdit}
										key={index}
										value={value}
										isLastCoin={
											budget + time === index + 1 &&
											(index + 1) % 5 === 0
												? true
												: false
										}
										isHorizontal={isHorizontal}
										alias={alias}
										name={name}
										type={"time"}
										coinType={ITEMS.time}
									/>
								))
						: null}
				</div>
				<Modal
					className="ContainerPropertiesPopup"
					isOpen={modal}
					toggle={toggle}
				>
					<ModalHeader className="mb-4" toggle={toggle} >
						<Translate alias={'Board'} word={'remaining coins'}/>
					</ModalHeader>
					<ContainerModal
						coins={budget ? budget : time}
						type={budget || name === "General" || name === "Marketing" ? "budget" : "time"}
						alias={"Invested"} name={name}
					/>
				</Modal>
			</div>
		</>
	);
};

export default InvestedDraggableContainer;
