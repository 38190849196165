import React from "react";
import styled from "styled-components";

// Images
import { ReactComponent as Person } from "../../assets/SVG/person.svg";
import { ReactComponent as Arrow } from "../../assets/SVG/left-arrow.svg";
import HappyFace from "../../assets/images/HappyFace.png";
import SadFace from "../../assets/images/SadFace.png";
import Translate from "../../utils/Translate";

const StyledDiv = styled.div`
	top: ${(props) => props.top}px;
	right: ${(props) => props.right}px;
	::before {
		transform: ${(props) =>
			props.translateB4
				? `rotate(${props.angle}deg) translate(${props.translateB4}px)`
				: `rotate(${props.angle}deg)`};
		border-color: ${(props) =>
			props.beforeBG === "#BFC5C9" ? "white" : props.beforeBG};
		height: ${(props) => props.beforeH8}px;
	}
	::after {
		transform: rotate(${(props) => props.angle}deg)
			translate(${(props) => props.translate}px);
		border-color: ${(props) => props.afterBG};
		height: ${(props) => props.afterH8}px;
	}
`;
const BeforeSvgStyle = styled.svg`
	width: 9px;
	svg > g > g > * {
		fill: ${(props) => props.beforeBG};
	}
`;
const AfterSvgStyle = styled.svg`
	width: 9px;
	svg > g > g > * {
		fill: ${(props) => props.afterBG};
	}
`;

const RelationCard = ({
	angle,
	translate,
	top,
	right,
	beforeBG,
	afterBG,
	beforeH8,
	afterH8,
	translateB4,
	value,
	impactValue
}) => {
	return (
		angle,
		(
			<StyledDiv
				top={top}
				beforeBG={beforeBG}
				beforeH8={beforeH8}
				afterBG={afterBG}
				right={right}
				translateB4={translateB4}
				afterH8={afterH8}
				angle={angle}
				translate={translate}
				className="relationship"
			>
				<div className="relationship-card">
					{
						(impactValue !== 0) ?
							<div className={ `impact-visual-dots ${ impactValue > 0 ? "green" : "red" }` }>
								{ impactValue > 0 ? "+" : "" }{ impactValue }
							</div>
							:
							null
					}
					<img
						src={value >= 0 ? HappyFace : SadFace}
						alt=""
						className="happy-face"
					/>
					<div
						className="relation-value"
						style={
							value >= 0
								? { color: "#FCB813" }
								: { color: "#531225" }
						}
					>
						{value}
					</div>
					<BeforeSvgStyle beforeBG={beforeBG}>
						<Person />
					</BeforeSvgStyle>
					<AfterSvgStyle afterBG={afterBG} className="left-arrow">
						<Arrow />
					</AfterSvgStyle>
					<BeforeSvgStyle beforeBG={beforeBG} className="right-arrow">
						<Arrow />
					</BeforeSvgStyle>

					<AfterSvgStyle afterBG={afterBG}>
						<Person />{" "}
					</AfterSvgStyle>
					<span>
						<Translate alias="Board" word="Relationship" />
					</span>
				</div>
			</StyledDiv>
		)
	);
};

export default RelationCard;
