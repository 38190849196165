const TeamPosition = {
	Blue: {
		top: "45",
		right: "418",
		color: "#005B96"
	},
	TeamLeader: {
		top: "341",
		right: "46",
		color: "#899196"
	},
	White: {
		top: "172",
		right: "804",
		color: "#899196"
	},
	Red: {
		top: "781",
		right: "49",
		color: "#D01349"
	},
	Green: {
		top: "563",
		right: "542",
		color: "#00a94f"
	},
	Yellow: {
		top: "849",
		right: "893",
		color: "#FCB813"
	},
}

export default TeamPosition;
