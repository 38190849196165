import React, { useEffect, useState } from "react";
import { Input, Label, Table } from "reactstrap";

// Images
import container from "../../assets/images/container-h.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewGoldCoinImg from "../../assets/images/dollar_coin_silver-preview.png";

// CSS
import "../../assets/css/ContainerModal.css";
import { Button, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import RealtimeConnection from "../../utils/RealtimeConnection";
import Translate from "../../utils/Translate";

const formatNumber = new Intl.NumberFormat("en-US");
export default function ContainerModal({ coins, type, name = "", alias = "" , setIsShown }) {
	let coinsList = {};
	const [isSubstracting, setIsSubstracting] = useState(true);
	const [substractAmount, setSubstractAmount] = useState(null);
	const [investedDestinationContainer , setInvestedDestinationContainer] = useState('')
	// const [addAmount, setAddAmount] = useState(0);
	// const [isAdding, setIsAdding] = useState(false);

	// const [addingError, setAddingError] = useState("");
	const [subtactError, setSubtactError] = useState("");

	const tableCoinsState = useSelector((state) => state.teamTable);
	// const timeCoins = tableCoinsState.timeCoins || 0;
	// const moneyCoins = tableCoinsState.moneyCoins || 0;

	const newCoins = coins;
	const getCoinRow = (key, imgSrc, count, value) => {
		return (
			<tr key={key}>
				<td>
					<img src={imgSrc} className="prevCoinImg" alt="" />
				</td>
				<td>{count}</td>
				<td>{formatNumber.format(parseFloat(value) * count)}</td>
			</tr>
		);
	};

	useEffect(()=>{
		if (alias === 'Teams' && type === 'budget') {
			setInvestedDestinationContainer("General")
		}else{
			setInvestedDestinationContainer(name)
		}
	}, [name]);

	const setAmountHandler = (e) => {
		setSubstractAmount(+e.target.value);
	};

	const renderCoins = (passedCoins) => {
		const propsCoins = coins;
		const newCoins = passedCoins ? passedCoins : propsCoins;
		return (
			<>
				{Array(newCoins)
					.fill(type === "time" ? 1 : 10)
					.map((value, index) => {
						const isLastCoin =
							coins.length === index + 1 && (index + 1) % 5 === 0
								? true
								: false;
						let coinClass = isLastCoin
							? "last-coin "
							: "not-last-coin ";
						switch (value) {
							case 1:
								coinClass += "gold";
								break;
							case 10:
								coinClass += "silver";
								break;

							default:
								break;
						}
						return (
							<div className={["coin", coinClass].join(" ")} />
						);
					})}
			</>
		);
	};

	// const addCoinsHandler = (e) => {
	// 	setAddAmount(+e.target.value);
	// };

	// const handleIncreaseResources = () => {
	// 	console.log(timeCoins);
	// 	if (
	// 		(type === "time" && timeCoins - addAmount >= 0) ||
	// 		(type === "budget" && moneyCoins - addAmount >= 0)
	// 	) {
	// 		RealtimeConnection.addCoin({
	// 			name: name,
	// 			alias: alias,
	// 			type: type,
	// 			coinsCount: addAmount,
	// 		});
	// 		RealtimeConnection.decreaseTeamTable({
	// 			coinType: type === "time" ? "timeCoins" : "moneyCoins",
	// 			value: addAmount,
	// 		});
	// 	} else {
	// 		if (type === "time") {
	// 			setAddingError(
	// 				`you only have ${timeCoins} ${type} coins, you cant add more than that!`
	// 			);
	// 		} else {
	// 			setAddingError(
	// 				`you only have ${moneyCoins} ${type} coins, you cant add more than that!`
	// 			);
	// 		}
	// 	}
	// };

	const justTranslate = function(word , alias)
	{
		let translatedWord = word

		if (window.staticWords) {
			if (window.staticWords[alias]) {
				if (window.staticWords[alias][word]) {
					translatedWord = window.staticWords[alias][word]
				}
			}
	}
	return translatedWord
	}

	const handleDecreaseRources = () => {
		if(substractAmount < 0){
			return
		}
		if (newCoins - substractAmount >= 0) {
			if (!substractAmount) {
				return
			}
			RealtimeConnection.removeCoin({
				name: name,
				alias: alias,
				type: type,
				coins: substractAmount,
			});
			const addOptions = {
				alias: 'Invested',
				name : investedDestinationContainer,
				type,
				coinsCount: substractAmount,
			}
			RealtimeConnection.addCoin(addOptions)
			
			setIsShown(false)
			// RealtimeConnection.increaseTeamTable({
			// 	coinType: type === "time" ? "timeCoins" : "moneyCoins",
			// 	value: substractAmount,
			// });
		} else {
			if (type === "time") {
				setSubtactError(
					`this container only contains ${coins} ${type} coins , please inter a valid amount`
				);
			} else {
				setSubtactError(
					`this container only contains ${coins} ${type} coins , please inter a valid amount`
				);
			}
		}
	};

	if (coins > 0) {
		Array(coins)
			.fill(type === "time" ? 1 : 10)
			.map((value) => {
				coinsList[`${value}`] = coinsList[`${value}`]
					? coinsList[`${value}`] + 1
					: 1;
			});
	}

	let coinsRows = [];
	let totalCoinsValue = 0;
	let totalCoinsCount = 0;

	if (coinsList[`10`]) {
		totalCoinsValue += 1 * parseFloat(coinsList[`10`]);
		totalCoinsCount += parseFloat(coinsList[`10`]);

		coinsRows.push(getCoinRow(0, previewGoldCoinImg, coinsList[`10`], 1));
	}
	if (coinsList[`1`]) {
		totalCoinsValue += 1 * parseFloat(coinsList[`1`]);
		totalCoinsCount += parseFloat(coinsList[`1`]);

		coinsRows.push(getCoinRow(1, previewSilverCoinImg, coinsList[`1`], 1));
	}

	return (
		<>
			<div className="containerObjClassModal">
				<div style={{position : 'relative' , display : 'flex' , justifyContent : 'center'}}>
					<div
						className="coins-sum"
						style={
							coins
								? { display: "block" }
								: { display: "none" }
						}
					>
						<p style={{ margin: 0 }}>{coins}</p>
					</div>
					<img className="objModelClass" src={container} alt="" />
				</div>

				<div className="coins-container">
					<div className="helper"></div>
					{newCoins > 0 ? renderCoins(newCoins) : null}
				</div>
			</div>
			<div className="clearfix"></div>

			<div style={{ height: 20 }}></div>
			{/* <Table striped bordered>
				<thead>
					<tr style={{ color: "white", backgroundColor: "#002955" }}>
						<th>Value</th>
						<th>Count</th>
						<th>{type === "time" ? "Time" : "Cash"}</th>
					</tr>
				</thead>
				<tbody>{coinsRows}</tbody>
				<tbody>
					<tr>
						<td className="bold-text">Total</td>
						<td className="bold-text">{totalCoinsCount}</td>
						<td className="bold-text">
							{formatNumber.format(totalCoinsValue)}
						</td>
					</tr>
				</tbody>
			</Table> */}
			<div className="horizontal-separator">&nbsp;</div>
			{!isSubstracting && alias !== 'Invested' && (
				<Row className="justify-content-between">
					{/* <Button
						onClick={() => {
							setIsAdding(true);
						}}
						variant="outline-primary mx-4"
					>
						Add
					</Button> */}
					<Button
						onClick={() => {
							setIsSubstracting(true);
						}}
						variant="outline-primary btn-block mx-4"
					>
						<Translate alias="Board" word="subtract" />
					</Button>
				</Row>
			)}
			{isSubstracting && alias != 'Invested' && (
				<>
					<Input
						type="number"
						min={1}
						max={coins}
						value={substractAmount}
						onChange={setAmountHandler}
					/>
					{type === 'budget' && (
						<div className="my-2">
						<span className="text-sm text-gray text-capitalize">
							<Translate alias="Board" word="destination container" />
						</span>
						<Input id="destinationContainer"
						onChange={(e)=>{setInvestedDestinationContainer(e.target.value)}}
						 className="" type="select">
							<option value={'General'}>
								{justTranslate('Money Coins', 'invested area and profiles')}
							</option>
							<option value={'TeamLeader'}>
							{justTranslate('TeamLeader Coins', 'invested area and profiles')}
							</option>
							<option value={'Marketing'}>
								{justTranslate('Marketing Coins', 'invested area and profiles')}
							</option>
						</Input>
					</div>
					)}
					{subtactError && (
						<span className="text-danger text-sm">
							{subtactError}
						</span>
					)}
					<Button
						onClick={handleDecreaseRources}
						variant="outline-danger"
						className="mt-2"
					>
						<Translate alias={'Board'} word={'subtract'}/>
					</Button>
				</>
			)}
			{/* {isAdding && (
				<>
					<Input
						type="number"
						min={
							type == "time"
								? timeCoins > 1
									? 1
									: 0
								: moneyCoins > 1
								? 1
								: 0
						}
						max={
							type == "time"
								? timeCoins > 1
									? 20 - coins
									: 0
								: moneyCoins > 1
								? 20 - coins
								: 0
						}
						value={addAmount}
						onChange={addCoinsHandler}
					/>
					{addingError && (
						<span className="text-danger text-sm">{addingError}</span>
					)}
					<Button
						onClick={handleIncreaseResources}
						variant="outline-success"
						className="mt-2"
					>
						Add
					</Button>
				</>
			)} */}
		</>
	);
}
