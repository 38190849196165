import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

// Constants
import URLs from "../utils/urls";

// Images
import { ReactComponent as Arrow } from "../assets/SVG/arrow.svg";
import Like from "../assets/images/HappyFace.png";
import UnLike from "../assets/images/SadFace.png";

// CSS
import "../assets/css/EventCard.css";

// Helpers
import RealtimeConnection from "../utils/RealtimeConnection";
import ProbabilityCard from "./Blocks/ProbabilityCard";
import Translate from "../utils/Translate";
import _Slide from "./_EventComponents/_Slide";
import _Option from "./_EventComponents/_Option";
import _CoinImpactRow from "./_EventComponents/_CoinImpactRow";
import _MoneyInvestRow from "./_EventComponents/_MoneyInvestRow";
import _ResultImpactRow from "./_EventComponents/_ResultImpactRow";
import Swal from "sweetalert2";

export default function EventCard({ eventId, viewLastActiveEvent, lastActiveEventId, dispatch }) {

	const [currentEventId, setCurrentEventId] = useState(undefined)
	const [eventSectionStatus, setEventSectionStatus] = useState({})
	const [show, setShow] = useState(false);
	const [eventData, setEventData] = useState(undefined);
	const [selectedOption, setSelectedOption] = useState(undefined);
	const [cards, setCards] = useState(undefined);
	const [showTimeCoins, setShowTimeCoins] = useState(true)
	const [showMoneyCoins, setShowMoneyCoins] = useState(true)

	const sessionState = useSelector((state) => state.session);
	const teamSelectedEventOption = sessionState.teamSelectedEventOption;

	const capitalizeFirstLetter= (word)=>{
		const firstLetterCapitalized = word.charAt(0).toUpperCase()
		return firstLetterCapitalized+word.slice(1)
	}

	useEffect(() => {
		if (eventId) {
			setCurrentEventId(eventId)
			setShow(true)
		}else{
			setShow(false)
		}
	}, [eventId])

	useEffect(() => {
		if (lastActiveEventId) {
			setCurrentEventId(lastActiveEventId)
		}
	}, [lastActiveEventId])

	useEffect(() => {
		if (viewLastActiveEvent) {
			setShow(true);
			fetchLastActiveEvent(lastActiveEventId);
		}
	}, [viewLastActiveEvent]);

	useEffect(() => {
		if (sessionState.eventSectionStatus && sessionState.eventSectionStatus[currentEventId]) {
			setEventSectionStatus(sessionState.eventSectionStatus[currentEventId])
		}else{
			setEventSectionStatus({})
		}
	}, [sessionState, currentEventId])

	const handleClose = () => {
		setShow(false);
		dispatch({ type: "ClOSE_LAST_ACTIVE_EVENT" });
	};

	/*useEffect(() => {
		if (selectedOption) {
			let timeCoins = {};
			let characters = [
				"teamLeader",
				"red",
				"blue",
				"green",
				"yellow",
				"white"
			];
			characters.map((x) =>
				selectedOption[x] !== 0 ? (timeCoins[x] = selectedOption[x]) : null
			);
			RealtimeConnection.updateTimeImpact({ obj: timeCoins });
		}
	}, [selectedOption]);

	useEffect(() => {
		if (selectedOption) {
			let moneyCoins = {};
			selectedOption.SimteamEventOptionMoneyInvests.map(
				(x) => (moneyCoins[x.character] = x.amount)
			);
			RealtimeConnection.updateMoneyImpact({ obj: moneyCoins });
		}
	}, [selectedOption]);*/


	useEffect(() => {
		if (sessionState.selectedEvents && eventData) {
			if (sessionState.selectedEvents[eventData.id]) {
				setSelectedOption(sessionState.selectedEvents[eventData.id]);
			}
		}
	}, [sessionState.selectedEvents, eventData]);

	useEffect(() => {
		if (selectedOption && selectedOption.probabilityCards) {
			setCards(selectedOption.probabilityCards);
		}
	}, [selectedOption]);

	useEffect(() => {
		if (eventData && eventData.SimteamCommonEventOptions) {
			const numberOfAvailableTimeItems = eventData.SimteamCommonEventOptions.filter( option => {
				if (option.teamLeader !== 0) {
					return true
				}
				if (option.red !== 0) {
					return true
				}
				if (option.blue !== 0) {
					return true
				}
				if (option.green !== 0) {
					return true
				}
				if (option.yellow !== 0) {
					return true
				}
				if (option.white !== 0) {
					return true
				}

				return false
			})

			const numberOfAvailableMoneyItems = eventData.SimteamCommonEventOptions.filter( option => {
				if (option.SimteamEventOptionMoneyInvests.length > 0) {
					return true
				}

				return false
			})


			setShowTimeCoins(numberOfAvailableTimeItems.length > 0 ? true : false)
			setShowMoneyCoins(numberOfAvailableMoneyItems.length > 0 ? true : false)
		}
	}, [eventData])

	const fetchEvent = async (id) => {
		const result = await axios.post(
			URLs.eventLink,
			{
				playerId: !isNaN(window.playerId) ? window.playerId : 0,
				adminId: !isNaN(window.adminId) ? window.adminId : 0,
				teamId: window.teamId,
				token: window.token,
				sessionId: window.sessionId,
				languageId: localStorage.langId,
			},
			{ headers: { "content-type": "application/json" } }
		);

		if (result.data.success) {
			setEventData(result.data.event);
		} else {
		}
	};
	const fetchLastActiveEvent = async (id) => {
		const result = await axios.post(
			URLs.fetchEvent,
			{
				token: window.token,
				eventId: id,
				languageId: localStorage.langId,
			},
			{ headers: { "content-type": "application/json" } }
		);

		if (result.data.success) {
			setEventData(result.data.event);
		} else {
		}
	};

	useEffect(() => {
		if (eventId) {
			setShow(true);
			fetchEvent(eventId);
		}
	}, [eventId]);

	const justTranslate = function(word , alias)
	{
		let translatedWord = word

		if (window.staticWords) {
			if (window.staticWords[alias]) {
				if (window.staticWords[alias][word]) {
					translatedWord = window.staticWords[alias][word]
				}
			}
	}
	return translatedWord
	}

	const showConfirmationModal = (optionData)=>
	{
		if (!eventSectionStatus.allowOptionSelection || teamSelectedEventOption) return
		Swal.fire({
			title: justTranslate('are you sure warning' , 'events and colors'),
			showCancelButton: true,
			cancelButtonText: justTranslate('no','events and colors'),
			confirmButtonText: justTranslate('yes','events and colors'),
		  }).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				onOptionSelect(optionData)
			}
		  })
	}

	const onOptionSelect = (optionData) => {
		if (eventSectionStatus.allowOptionSelection) {
			RealtimeConnection.selectOption(eventData, optionData);
		}
		/*if (optionData.hasProbability) {
			if (sessionState.probabilityCard[eventData.id]) {
				let cardStatus =
					sessionState.probabilityCard[eventData.id] === "happy"
						? true
						: false;
				setCards([cardStatus]);
			} else {
				if (optionData.probability) {
					let happyCount = optionData.probability / 10;
					let sadCount = 10 - optionData.probability / 10;
					let arr = [
						...Array(happyCount).fill(true),
						...Array(sadCount).fill(false),
					];
					for (var c = arr.length - 1; c > 0; c--) {
						var b = Math.floor(Math.random() * (c + 1));
						var a = arr[c];
						arr[c] = arr[b];
						arr[b] = a;
					}
					setCards(arr);
				}
			}
		}*/
	};

	return (
		<Modal
			show={ show } //(eventId || viewLastActiveEvent) && show}
			className="event-modal"
			onHide={handleClose}
		>
			{eventData && currentEventId && (
				<React.Fragment>
					{
						(sessionState.eventSlideAppearance.enabled) ?
							<_Slide 
								title={ sessionState.eventSlideAppearance.slide.title }
								body={ sessionState.eventSlideAppearance.slide.body }
							/>
							:
							<React.Fragment>
								<div className="event-header">
									{ eventData.description }
								</div>
								<div className="event-wrapper">
									<div className="event-container" style={{ width: 345 * eventData.SimteamCommonEventOptions.length }}>
										{/* Options */}
										<div className="probability-container">
											{eventData.SimteamCommonEventOptions.map(option => {
													return(
														<_Option 
															key={ option.id }
															selectedOption={ selectedOption }
															option={ option }
															onOptionSelect={ showConfirmationModal }
														/>
													)
												}
											)}
										</div>
									
										{/* time Coins */}
										{eventSectionStatus.showTimeCoins && showTimeCoins && (
												<React.Fragment>
													<div className="time-coins-title font-weight-bold">
														<div className="stickyHeader">
															<Translate alias="events and colors" word="effect on time coins" />
														</div>
													</div>
													<div className="probability-container">
														{eventData.SimteamCommonEventOptions.map((option, index) => (
																<div key={index}>
																	{
																		(option.teamLeader !== 0 &&
																			<_CoinImpactRow option={option} character="teamLeader" />
																		)
																	}
																	{
																		(option.red !== 0 &&
																			<_CoinImpactRow option={option} character="red" />
																		)
																	}
																	{
																		(option.blue !== 0 &&
																			<_CoinImpactRow option={option} character="blue" />
																		)
																	}
																	{
																		(option.green !== 0 &&
																			<_CoinImpactRow option={option} character="green" />
																		)
																	}
																	{
																		(option.yellow !== 0 &&
																			<_CoinImpactRow option={option} character="yellow" />
																		)
																	}
																	{
																		(option.white !== 0 &&
																			<_CoinImpactRow option={option} character="white" />
																		)
																	}
																</div>
															)
														)}
													</div>
												</React.Fragment>
										)}

										{/* Money Coins */}
										{eventSectionStatus.showMoneyCoins && showMoneyCoins && (
											<React.Fragment>
												<div className="time-coins-title font-weight-bold">
													<div className="stickyHeader">
														<Translate alias="events and colors" word="effect on money coins" />
													</div>
												</div>
												<div className="probability-container">
													{
														eventData.SimteamCommonEventOptions.map((option, index) => (
															<_MoneyInvestRow
																key={ index }
																option={ option }
															/>
														))
													}
												</div>
											</React.Fragment>
										)}

										{/* Possibilities */}
										{eventSectionStatus.showProbability && (
											<React.Fragment>
												{selectedOption &&
													selectedOption.hasProbability && (
														<div className="time-coins-title font-weight-bold">
															<div className="stickyHeader">
																<Translate
																	alias="events and colors"
																	word="your chances to solve this problem are"
																/>
															</div>
														</div>
													)}
												<div
													className="probability-container"
													style={{
														borderBottom: "1px solid",
														marginBottom: "15px",
													}}
												>
													{eventData.SimteamCommonEventOptions.map(
														(option, index) => (
															<React.Fragment key={index}>
																{option &&
																	option.hasProbability && (
																		<div>
																			<div className="event-option-title">
																				{selectedOption?.title ===
																					option.title && (
																					<div>
																						{ option.probability }%
																					</div>
																				)}
																			</div>
																			<div
																				style={{
																					display:
																						"grid",
																					gridTemplateColumns:
																						"1fr 1fr 1fr 1fr",
																				}}
																			>
																				{selectedOption?.title === option.title ? (
																					cards?.map((x, index) => (
																							<div key={ index } className="card-grid">
																								<ProbabilityCard
																									eventId={ eventId }
																									selectedOption={ selectedOption }
																									probabilityCard={
																										sessionState.probabilityCard &&
																										sessionState.probabilityCard[eventId]
																									}
																									cardStatus={x}
																									index={index}
																								/>
																							</div>
																						)
																					)
																				) : (
																					<div className=" font-weight-bold justify-content-center">
																						{ option.probability }%
																					</div>
																				)}
																			</div>
																		</div>
																	)}
															</React.Fragment>
														)
													)}
													{/*<div>
																	<br />
																	<div className=" font-weight-bold justify-content-center">10%</div>
																</div>
																<div>
																	<div className=" font-weight-bold justify-content-center">
																		<span>Your chances to solve this problem are</span>
																	</div>
																	<div className="font-weight-bold justify-content-center">50%</div>
																</div>
																<div>
																	<br />
																	<div className=" font-weight-bold justify-content-center">40%</div>
																</div>*/}
												</div>
											</React.Fragment>
										)}

										{eventSectionStatus.showImpact && (
											<React.Fragment>
												{/* Successful */}

												{selectedOption && selectedOption.hasProbability && (
													<div
														className="time-coins-title justify-content-center"
														style={{
															display: "flex",
															alignItems: "center",
															flexDirection: "column",
															paddingLeft: "25px",
														}}
													>
														<div className="font-weight-bold justify-content-center">
															<span className="emojis">
																<img src={Like} alt="" />
																<img src={UnLike} alt="" />
															</span>
														</div>
														<div className="noBorder font-weight-bold justify-content-center">
															<span>
																<Translate alias="events and colors" word="successful"/>{" "}/{" "}
																<Translate alias="events and colors" word="not successful"/>
															</span>
														</div>
													</div>
												)}

												{
													(selectedOption && selectedOption.hasProbability) ? 
														<div className="successful-container">
															<div>
																{/* Successful  */}
																{eventData.SimteamCommonEventOptions.map((option, index) => {
																		if (option?.title === selectedOption?.title) {
																			return (
																				<div key={index}>
																					{ /*<div className="event-option-title">
																						{ option.title }
																						:
																					</div>*/ }
																					{option.SuccessSystemImpact &&
																						option.SuccessSystemImpact.map(
																							(impact, index2) => (
																								<div key={index2}>
																									<span
																										style={{ textTransform: "capitalize" }}
																									>
																										<Translate key={index2} word={impact.impactType} alias={'events and colors'} /> { " "}
																										<Translate key={index2} word={impact.impactOn} alias={'events and colors'} />
																									</span>
																									<span
																										className={`${
																											impact.value >
																											0
																												? "positive"
																												: "negative"
																										}`}
																									>
																										<Arrow
																											className={`arrow ${
																												impact.value >
																												0
																													? "up"
																													: ""
																											}`}
																										/>
																										{impact.value >
																										0
																											? "+"
																											: ""}
																										{
																											impact.value
																										}
																									</span>
																								</div>
																							)
																						)}
																				</div>
																			);
																		}
																	}
																)}
															</div>
															<div>
																{/* Not successful  */}
																{eventData.SimteamCommonEventOptions.map(
																	(option, index) => {
																		if (
																			option?.title ===
																			selectedOption?.title
																		) {
																			return (
																				<div
																					key={index}
																				>
																					{ /*<div className="event-option-title">
																						{
																							option.title
																						}
																						:
																					</div>*/ }
																					{option.FailureSystemImpact &&
																						option.FailureSystemImpact.map(
																							(
																								impact,
																								index2
																							) => (
																								<div
																									key={
																										index2
																									}
																								>
																									<span
																										style={{
																											textTransform:
																												"capitalize",
																										}}
																									>
																										<Translate key={index2} word={impact.impactType} alias={'events and colors'} /> { " "}
																										<Translate key={index2} word={impact.impactOn} alias={'events and colors'} />
																									</span>
																									<span
																										className={`${
																											impact.value >
																											0
																												? "positive"
																												: "negative"
																										}`}
																									>
																										<Arrow
																											className={`arrow ${
																												impact.value >
																												0
																													? "up"
																													: ""
																											}`}
																										/>
																										{impact.value >
																										0
																											? "+"
																											: ""}
																										{
																											impact.value
																										}
																									</span>
																								</div>
																							)
																						)}
																				</div>
																			);
																		}
																	}
																)}
															</div>
														</div>
														:
														<React.Fragment>
															<div className="time-coins-title font-weight-bold">
																<div className="stickyHeader">
																	<Translate alias="events and colors" word="Results of your choice" />
																</div>
															</div>
															<div className="choice-result-container">
																<div className="impact-wrapper">
																	{
																		(selectedOption &&
																			<React.Fragment>
																				{selectedOption.SuccessSystemImpact &&
																					selectedOption.SuccessSystemImpact.map((impact, index2) => (
																						<_ResultImpactRow 
																							key={index2}
																							impact={impact}
																						/>
																					)
																				)}
																				{selectedOption.FailureSystemImpact &&
																					selectedOption.FailureSystemImpact.map((impact, index2) => (
																						<_ResultImpactRow 
																							key={index2}
																							impact={impact}
																						/>
																					)
																				)}
																			</React.Fragment>	
																		)
																	}
																</div>
															</div>
														</React.Fragment>
												}
											</React.Fragment>
										)}
									</div>
								</div>
							</React.Fragment>
					}
				</React.Fragment>
			)}
		</Modal>
	);
}
