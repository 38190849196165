import React, { useState } from "react";
import "../../assets/css/FixedBars.css";
import "../../assets/css/customerCardModal.css";
import kitsIcon from "../../assets/images/kits-icon.png";
import Translate from "../../utils/Translate";
import MainDraggableCoin from "../Draggables/MainDraggableCoin";

import MainDraggableContainer from "../Draggables/MainDraggableContainer";
import MainDroppableDelete from "../Droppables/MainDroppableDelete";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const FixedLeftBar = ({ canEdit, state }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isMenuOpened, setMenuOpened] = useState(false);
	const profilesHistory = useSelector(
		(state) => state.session.sessionCustomersHistory
	);
	const teamProfilesHistory = profilesHistory.filter(
		(profile) => profile.teamId === window.teamId
	);


	const updateUI = (panelType, status) => {
		const appContainer =
			document.getElementsByClassName("center-container");
		const menuContainer = document.getElementsByClassName("kitContainer");
		const rightBar = document.getElementsByClassName("right-bar");
		const customerCard = document.getElementsByClassName("customer-cards");

		if (status === "close") {
			appContainer[0].classList.remove("moveLeft");
			rightBar[0].classList.remove("moveRight");
			menuContainer[0].classList.remove("fullWidth");
			if (customerCard[0]) {
				customerCard[0].classList.remove("cards-moveLeft");
			}
		} else {
			appContainer[0].classList.remove("moveRight");
			appContainer[0].classList.add("moveLeft");
			rightBar[0].classList.add("moveRight");
			if (customerCard[0]) {
				customerCard[0].classList.add("cards-moveLeft");
			}
			if (panelType === "MENU") {
				menuContainer[0].classList.add("fullWidth");
			} else {
				setMenuOpened(false);
			}
		}
	};

	const toggleMenu = () => {
		if (isMenuOpened) {
			setMenuOpened(false);
			updateUI("MENU", "close");
		} else {
			setMenuOpened(true);
			updateUI("MENU", "open");
		}
	};

	return (
		<>
			<div className="kits-objects-btn" onClick={toggleMenu}>
				<div className="button">
					<img alt="kits-icon" src={kitsIcon} />
					<div className="text">
						<Translate alias="Board" word="Team Table" />
					</div>
				</div>
			</div>
			<div className="kitContainer">
				<div className="kits-header">
					<div className="kits-header-logo">
						<img alt="kits-icon" src={kitsIcon} />
						<div className="text">
							<Translate alias="Board" word="Team Table" />
						</div>
					</div>
				</div>
				<div className="blur">
					<div className="object-container-div">
						<div className="container-text">
							<Translate alias="Board" word="Containers" />
						</div>
						<MainDraggableContainer canEdit={canEdit} />
					</div>
					{/* {
						<div className="object-container-div">
							<div className="container-text">
								Time
								<div className="object-value">
									<span className="notranslate">
										{state.timeCoins}
									</span>
								</div>
							</div>
							<div className="d-flex justify-content-center">
								<div>
									<MainDraggableCoin
										value={1}
										canEdit={canEdit}
										canMoveCoins={state.timeCoins > 0}
									/>
								</div>
							</div>
						</div>
					}
					<div className="kits-separator" />
					{
						<div className="object-container-div">
							<div className="container-text">
								Money
								<div className="object-value">
									<span className="notranslate">
										{state.moneyCoins}
									</span>
								</div>
							</div>
							<div className="d-flex justify-content-center">
								<div>
									<MainDraggableCoin
										value={10}
										canEdit={canEdit}
										canMoveCoins={state.moneyCoins > 0}
									/>
								</div>
							</div>
						</div>
					} */}

					<div className="object-delete-div">
						<div className="container-text">
							<Translate alias="Board" word="Delete" />
						</div>
						<MainDroppableDelete canEdit={canEdit} />
						<div className="clearfix"></div>
					</div>
					<div className="kits-separator"></div>
					<div className="d-flex my-5 justify-content-center align-items-center">
						<Button color="primary" onClick={()=>{setIsOpen(true) }} >
							<Translate alias={'Board'} word={'Customers History'} />
						</Button>
						<Modal isOpen={isOpen} toggle={()=>{setIsOpen(!isOpen)}}>
							<ModalHeader>
								<Translate alias={'Board'} word={'Customers History'} />
							</ModalHeader>
							<ModalBody style={{maxHeight:'480px' , overflowY : 'scroll'}}>
								<div  className="customer-cards-in-modal" style={{position: 'relative', overflow:"hidden" , flexDirection:'column' , marginTop: '20px', gap:'8px'}}>
								{teamProfilesHistory &&
									teamProfilesHistory.map((group, index) => (
										<>
										<div key={index}>
											<div
												style={{
													width: "32%",
													fontSize: "20px",
													overflowWrap: "anywhere",
												}}
											>
												<span>
													<Translate
														alias="Board"
														word="Customer Card"
													/>
												</span>
												<span
													style={{
														color: "#fdf854",
														marginLeft: "20px",
													}}
												>
													{teamProfilesHistory.length - index}
												</span>
											</div>

											<div
												style={{
													border: "1px solid white",
													height: "100%",
													margin: "0 10px",
												}}
											/>
											<div className="customerCardValueContainer">
												<div className="d-grid customer-card-value-row d-flex">
													<span>
														<Translate
															alias="Board"
															word="Prerequisites"
														/>
														:
													</span>
													<span
														style={{
															flex: 1,
															textAlign: "end",
														}}
													>
														{group.profile.SimteamCustomerCardTranslation
															? group.profile
																	.SimteamCustomerCardTranslation
																	.prerequisites
															: group.profile.prerequisites}
													</span>
												</div>
												<div className="d-grid customer-card-value-row d-flex">
													<span style={{ flex: 1 }}>
														<Translate
															alias="Board"
															word="Time Needed"
														/>
														:
													</span>
													<span
														style={{
															flex: 1,
															textAlign: "end",
														}}
													>
														{group.profile.time_needed}
													</span>
												</div>
												<div
													className="d-grid customer-card-value-row d-flex"
													style={{
														color: "#fdf854",
													}}
												>
													<span style={{ flex: 1 }}>
														<Translate
															alias="Board"
															word="Return"
														/>
														:
													</span>
													<span
														style={{
															textAlign: "end",
														}}
													>
														{group.profile.return}
													</span>
												</div>
											</div>
											
										</div>
										<section className="d-flex justify-content-between align-items-center" style={{fontSize:'12px', margin : '0 15px', color: 'black'}}>
											<p>Choice Maker</p>
											<p>{group.actionMaker}</p>
										</section>
										</>
									))}
								</div>
							
							</ModalBody>
							<ModalFooter>
								<Button onClick={()=>{setIsOpen(false) }} color="primary">
									<Translate alias="events and colors" word={'close'} />
									</Button>{" "}
							</ModalFooter>
						</Modal>
					</div>
					<div style={{ height: "30px" }}></div>
				</div>
			</div>
		</>
	);
};

export default FixedLeftBar;
