import React from "react";
import { useDrag, DragPreviewImage } from "react-dnd";

// Constants
import ITEMS from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Images
import previewGoldCoinImg from "../../assets/images/dollar_coin-preview.png";
import previewSilverCoinImg from "../../assets/images/dollar_coin_silver-preview.png";

let numberOfUpdates = 0;

export default function DraggableCoin({
	canEdit,
	containerId,
	value,
	alias,
	name,
	type,
	isLastCoin
}) {
	const [{ isDragging }, drag, preview] = useDrag({
		type: type === "time" ? ITEMS.time : ITEMS.coin,
		item: {
			type: type === "time" ? ITEMS.time : ITEMS.coin,
			name: name,
			coinType: type,
			value: value,
			containerId: containerId,
		},
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
		end: (dropResult, monitor) => {
			const doppableItem = monitor.getDropResult();
			if (monitor.didDrop()) {
				if (doppableItem && doppableItem.success) {
					console.log("Should Remove Coin");
					
					RealtimeConnection.removeCoin({
						alias: alias,
						name: name,
						type: type,
						coins: 1,
					});
				}
			}
		},
	});

	let previewImg = previewGoldCoinImg;
	let coinClass = isLastCoin ? "last-coin " : "not-last-coin ";
	switch (value) {
		case 1:
			coinClass += "gold";
			previewImg = previewGoldCoinImg;
			break;
		case 10:
			coinClass += "silver";
			previewImg = previewSilverCoinImg;
			break;
		default:
			break;
	}

	let canDrag = canEdit ? drag : null;

	let dragClass = "";
	/*if (isDragging) {
		dragClass = "dragging";

		if (numberOfUpdates === 0) {
			numberOfUpdates = 1;
			const dragObjData = {
				id: props.id,
				fieldType: props.productionType,
				index: props.index
			}
			props.isDraggingHandler("COIN", isDragging, dragObjData);
		}
	}else if (props.coinDraggingIndex === props.index && props.draggingType === "COIN") {
		dragClass = "dragging";
		canDrag = null;

		if (props.playerId === window.playerId){
			if (numberOfUpdates === 1) {
				numberOfUpdates = 0;
				const dragObjData = {
					id: props.id,
					fieldType: props.productionType,
					index: props.index
				}
				props.isDraggingHandler("COIN", isDragging, dragObjData);
			}
		}
	}*/

	return (
		<>
			<DragPreviewImage connect={preview} src={previewImg} />
			<div
				ref={canDrag}
				className={["coin", coinClass, dragClass].join(" ")}
			></div>
		</>
	);
}
