import React from "react";

import AppHandler from "./AppHandler";
import Swal from 'sweetalert2'

// CSS
import "./App.css";
import "./assets/css/main.css";
import "./assets/css/DraggableAndDroppable.css";
import "./assets/css/single-team.css";
import "./assets/css/Style3dModel.css";
import reducer from "./redux/index";
import { Provider } from "react-redux";

import { createStore } from "redux";
const store = createStore(reducer);

const App = () => {
	return (
		<Provider store={store}>
			<AppHandler />
		</Provider>
	);
};

export default App;
