import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";

import "../../assets/css/profile.css";
import Translate from "../../utils/Translate";

const Profile = ({ name, closeProfile }) => {
	const [show, setShow] = useState(true);
	const [characterProfile, setCharacterProfile] = useState([]);
	const [selectedEvents, setSelectedEvents] = useState([]);

	const sessionState = useSelector((state) => state.session);

	useEffect(() => {
		if (sessionState.charactersProfile[name.toLowerCase()]) {
			setCharacterProfile(
				sessionState.charactersProfile[name.toLowerCase()]
			);
		}
	}, [sessionState.charactersProfile]);

	useEffect(() => {
		const ids = Object.keys(sessionState.selectedEvents);
		console.log(ids);
		setSelectedEvents(ids);
	}, [sessionState.selectedEvents]);

	const handleClose = () => {
		setShow(false);
		closeProfile(false);
	};

	console.log(selectedEvents);
	return (
		<Modal show={show} onHide={handleClose} className="event-modal">
			<div className="character-profile-wrapper">
				<div className="character-profile-header">
					<div className="information-title">
						<Translate
							alias="invested area and profiles"
							word="information team member"
						/>
					</div>
					<div className={`characterName ${name.toLowerCase()}`}>
						<Translate
							alias="events and colors"
							word={name.toLowerCase()}
						/>
					</div>
				</div>
				<div className="character-profile-body">
					<table>
						<tbody>
							{characterProfile.map((profile, index) => {
								return (
									<React.Fragment key={index}>
										{(selectedEvents.includes(
											`${profile.commonEventId}`
										) ||
											!profile.commonEventId) && (
											<tr className="table-row">
												<td className="field-name">
													<Translate
														alias="invested area and profiles"
														word={profile.fieldName}
													/>
												</td>
												<td className="field-value">
													{profile.fieldValue}
												</td>
											</tr>
										)}
									</React.Fragment>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</Modal>
	);
};

export default Profile;
