import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

// Images
import CenterBoardPrev from "../assets/images/centerBoard.jpeg";
import logo from "../assets/images/Simdustry black logo.png";
import SimTeamLogo from "../assets/images/simTeam-logo.png";

// CSS
import "../assets/css/right-bar.css";
import CenterBoardCard from "./Blocks/CenterBoardCard";
import { findAllByTestId } from "@testing-library/dom";
import Translate from "../utils/Translate";
import { useSelector } from "react-redux";

export default function RightBar({ activeCustomerCardIds }) {

	const sessionState = useSelector(state => state.session)

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const [otherTeams, setOtherTeams] = useState([]);

	useEffect(() => {
		setOtherTeams(sessionState.centralBoardData)
		console.log("🚀 ~ file: RightBar.js:27 ~ useEffect ~ sessionState.centralBoardData:", sessionState.centralBoardData)
	}, [sessionState.centralBoardData])

	return (
		<div
			className="right-bar"
			style={
				activeCustomerCardIds
					? { paddingTop: "15%" }
					: { paddingTop: "130px" }
			}
		>
			<div onClick={handleShow}>
				<div className="teamscore">
					<span>
						<Translate alias="Board" word="Central Board" />
					</span>
					<div>
						<img
							src={CenterBoardPrev}
							alt=""
							className="central-board-img"
						/>
					</div>
				</div>
			</div>

			<Modal show={show} className="board-modal" onHide={handleClose}>
				<div className="board-modal-header">
					<img src={SimTeamLogo} alt="" />
					<img src={logo} alt="App-logo" style={{ width: "25%" }} />
				</div>
				<div
					className="w-100 d-flex flex-wrap justify-content-center m-auto"
					style={{ columnGap: "30px", rowGap: "10px" }}
				>
					
					{otherTeams.map((team, index) => (
						<CenterBoardCard
							key={ index }
							name={team.teamName}
							reputation={
								team.reputation ? team.reputation : 0
							}
							sumCompetence={team.sumCompetence}
							sumSatisfaction={team.sumSatisfaction}
							returnValue={team.return}
							teamScore={ team.teamScore }
						/>
					))}
					
				</div>
			</Modal>
		</div>
	);
}
