import initState from "../states/transfer-modal-init-state";
import {
	SHOW_TRANSFER_COINS_MODAL,
	HIDE_TRANSFER_COINS_MODAL,
	SET_TRANSFER_COINS_MODAL_COINS,
	SET_TRANSFER_COINS_MODAL_COINS_TYPE,
	SET_TRANSFER_COINS_MODAL_NAME,
	SET_TRANSFER_COINS_MODAL_ALIAS,
} from "../actions";

const reducer = (state = initState, action) => {
	switch (action.type) {
		case SHOW_TRANSFER_COINS_MODAL:
			return {
				...state,
				isVisible: true,
			};
			break;
		case HIDE_TRANSFER_COINS_MODAL:
			return {
				...state,
				isVisible: false,
			};
		case SET_TRANSFER_COINS_MODAL_COINS:
			return {
				...state,
				modalCoins: action.payload.coins || 0,
			};
			break;
		case SET_TRANSFER_COINS_MODAL_NAME:
			return {
				...state,
				name: action.payload.name || '0',
			};
			break;
		case SET_TRANSFER_COINS_MODAL_ALIAS:
			return {
				...state,
				alias: action.payload.alias || '',
			};
			break;
		case SET_TRANSFER_COINS_MODAL_COINS_TYPE:
			return { ...state, coinsType: action.payload.coinsType || "time" };
			break;
		default:
			break;
	}

	return state;
};

export default reducer;
