import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";

// Helpers
import ITEMS from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Images
import { ReactComponent as Time } from "../../assets/SVG/time.svg";
import { ReactComponent as MoneySign } from "../../assets/SVG/moneySign.svg";

// Components
import InvestedDraggableContainer from "../Draggables/InvestedDraggableContainer";
import Translate from "../../utils/Translate";
import { Button } from "react-bootstrap";

const InvestedDroppableField = ({
	canEdit,
	alias,
	name,
	data,
	teamContainer,
	timeCoinsImpact,
	moneyCoinsImpact,
}) => {
	// const [show, setShow] = useState(false);

	const [{ isOver, canDrop, containerId }, drop] = useDrop({
		accept: ITEMS.container,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			containerId: monitor.getHandlerId(),
		}),
		drop: (item, monitor) => {
			console.log(item);
			if (monitor.getHandlerId() !== item.containerId) {
				/*if (item.type === "Container") {
					RealtimeConnection.investedAddContainer({
						alias: alias,
						name: name,
						budget: 0,
						time: 0,
					});
				}*/
				RealtimeConnection.investedAddContainer({
					alias: alias,
					name: name,
					time: item.time ? item.time : item.coins,
					budget: 0,
				});

				return { success: true };
			}
			return null;
		},
	});

	const characterName = name.charAt(0).toLowerCase() + name.slice(1);
	let show = false;
	let impactObj = undefined;
	let impactEventId = undefined;
	let impactIndexValue = undefined;
	if (moneyCoinsImpact) {
		const eventsKeys = Object.keys(moneyCoinsImpact);
		if (eventsKeys.length > 0) {
			for (
				let eventIndex = 0;
				eventIndex < eventsKeys.length;
				eventIndex++
			) {
				const eventTimeImpacts =
					moneyCoinsImpact[eventsKeys[eventIndex]];
				for (
					let impactIndex = 0;
					impactIndex < eventTimeImpacts.length;
					impactIndex++
				) {
					const impact = eventTimeImpacts[impactIndex];
					if (!impact.status) {
						if (impact.impact[characterName]) {
							impactEventId = eventsKeys[eventIndex];
							impactIndexValue = impactIndex;
							impactObj = impact.impact;
							show = true;
						}
						break;
					}
				}
			}
		}
	}


	if (data.budget && impactObj) {
		console.log(parseInt(data.budget) - parseInt(impactObj.currentBudget));
	}

	// useEffect(() => {
	/*let show = false;
	if (timeCoinsImpact && Object.keys(timeCoinsImpact)[0] === undefined && moneyCoinsImpact && Object.keys(moneyCoinsImpact)[0]) {
		const el = Object.keys(moneyCoinsImpact)[0];

		if (el.toLowerCase() === name.toLowerCase()) {
			show = true;
		}
	}*/
	// }, [moneyCoinsImpact, timeCoinsImpact]);

	/*const handleNext = (e) => {
		e.stopPropagation();
		RealtimeConnection.nextMoneyImpact({ name: name.toLowerCase() });
	};*/
	const handleNext = (e) => {
		e.stopPropagation();
		RealtimeConnection.nextMoneyImpact({
			name: name.toLowerCase(),
			eventId: impactEventId,
			impactIndex: impactIndexValue,
		});
	};

	const playerCanDrop = canEdit && !data.hasContainer ? drop : null;
	const backgroundColor = isOver && canDrop && canEdit ? "#CCCCCC" : "";
	return (
		<React.Fragment>
			<div
				ref={playerCanDrop}
				style={{ backgroundColor: backgroundColor }}
			>
				{/* {impactObj && show && (
					<div className="react-tiny-popover-container-money">
						<div>
							<p>
								Move {impactObj[characterName]}
								{" Money Coins "}
								{impactObj[characterName] > 0
									? " in "
									: " out of "}{" "}
								{name + " "} Container to invested
							</p>
						</div>

						{parseInt(impactObj[characterName]) > 0 ? (
							<React.Fragment>
								{window.adminId ||
								parseInt(data.budget) -
									parseInt(impactObj.currentBudget) ===
									parseInt(impactObj[characterName]) ? (
									<Button
										className="nextBtnContainer"
										size="sm"
										onClick={(e) => handleNext(e)}
									>
										Next
									</Button>
								) : null}
							</React.Fragment>
						) : (
							<React.Fragment>
								{window.adminId ||
								parseInt(data.budget) -
									parseInt(impactObj.currentBudget) ===
									parseInt(impactObj[characterName] * -1) ? (
									<Button
										className="nextBtnContainer"
										size="sm"
										onClick={(e) => handleNext(e)}
									>
										Next
									</Button>
								) : null}
							</React.Fragment>
						)}
					</div>
				)} */}
				{data.hasContainer ? (
					<InvestedDraggableContainer
						canEdit={true}
						budget={data.budget ? data.budget : 0}
						time={data.time ? data.time : 0}
						isHorizontal={!teamContainer}
						alias={alias}
						name={name}
					/>
				) : (
					<React.Fragment>
						{name === "General" || name === "Marketing" ? (
							<React.Fragment>
								<MoneySign />
							</React.Fragment>
						) : (
							<React.Fragment>
								<MoneySign />
								<Time />
							</React.Fragment>
						)}
					</React.Fragment>
				)}
				<span style={name === "General" ? { color: "black" } : null}>
					<Translate
						alias="invested area and profiles"
						word={
							name === "General"
								? "Money Coins"
								: name === "Marketing"
								? "Marketing Coins"
								: `${name} Coins`
						}
					/>
				</span>
			</div>
		</React.Fragment>
	);
	{
		/*<React.Fragment>
		<img src={ teamContainer ? containerV : container } alt="container" width="100%" height="100%" />
		<div className={ teamContainer ? "Coins-vertical-droppable" : "Coins-droppable" }>
			<span className="helper" />
		</div>
	</React.Fragment>*/
	}
};

export default InvestedDroppableField;
