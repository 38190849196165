import initState from "../states/board-init-state";

const reducer = (state = initState, action) => {
	switch (action.type) {
		case "RESTORE_STATE":
			return {
				...action.data.state,
			};
		case "UPDATE_STATE": {
			if (action.actionType === "AddContainer") {
				if (action.data.alias === "Invested") {
					return {
						...state,
						[action.data.alias]: {
							...state[action.data.alias],
							[action.data.character]: {
								hasContainer: true,
								budget:
									state[action.data.alias][
										action.data.character
									].budget + action.data.budget,
								time:
									state[action.data.alias][
										action.data.character
									].time + action.data.time,
							},
						},
					};
				} else {
					return {
						...state,
						[action.data.alias]: {
							...state[action.data.alias],
							[action.data.character]: {
								...state[action.data.alias][
									action.data.character
								],
								[action.data.type]: {
									hasContainer: true,
									coins:
										state[action.data.alias][
											action.data.character
										][action.data.type].coins +
										action.data.coins,
								},
							},
						},
					};
				}
			} else if (action.actionType === "RemoveContainer") {
				if (action.data.alias === "Invested") {
					if (action.data.alias) {
						return {
							...state,
							[action.data.alias]: {
								...state[action.data.alias],
								[action.data.character]: {
									hasContainer: false,
									time: 0,
									budget: 0,
								},
							},
						};
					}else{
						return {
							...state
						}
					}
				} else {
					if (action.data.alias) {
						return {
							...state,
							[action.data.alias]: {
								...state[action.data.alias],
								[action.data.character]: {
									...state[action.data.alias][action.data.character],
									[action.data.type]: {
										hasContainer: false,
										coins: 0,
									},
								},
							},
						};
					}else{
						return {
							...state
						}
					}
				}
			} else if (action.actionType === "AddCoin") {
				if (action.data.alias === "Invested") {
					return {
						...state,
						[action.data.alias]: {
							...state[action.data.alias],
							[action.data.character]: {
								...state[action.data.alias][
									action.data.character
								],
								[action.data.type]:
									state[action.data.alias][
										action.data.character
									][action.data.type] + action.data.coins,
							},
						},
					};
				} else {
					return {
						...state,
						[action.data.alias]: {
							...state[action.data.alias],
							[action.data.character]: {
								...state[action.data.alias][
									action.data.character
								],
								[action.data.type]: {
									...state[action.data.alias][
										action.data.character
									][action.data.type],
									coins:
										state[action.data.alias][
											action.data.character
										][action.data.type].coins +
										action.data.coins,
								},
							},
						},
					};
				}
			} else if (action.actionType === "RemoveCoin") {
				if (action.data.alias === "Invested") {
					return {
						...state,
						[action.data.alias]: {
							...state[action.data.alias],
							[action.data.character]: {
								...state[action.data.alias][
									action.data.character
								],
								[action.data.type]:
									state[action.data.alias][
										action.data.character
									][action.data.type] - action.data.coins,
							},
						},
					};
				} else {
					return {
						...state,
						[action.data.alias]: {
							...state[action.data.alias],
							[action.data.character]: {
								...state[action.data.alias][
									action.data.character
								],
								[action.data.type]: {
									...state[action.data.alias][
										action.data.character
									][action.data.type],
									coins:
										state[action.data.alias][
											action.data.character
										][action.data.type].coins -
										action.data.coins,
								},
							},
						},
					};
				}
			}
		}
		case "UPDATE_CHARACTER": {
			switch (action.data.type) {
				case "time":
				case "budget":
					return {
						...state,
						Teams: {
							...state.Teams,
							[action.data.character]: {
								...state.Teams[action.data.character],
								[action.data.type]: {
									...state.Teams[action.data.character][
										action.data.type
									],
									hasContainer:
										action.data.value > 0
											? true
											: state.Teams[
													action.data.character
											  ][action.data.type].hasContainer,
									coins: action.data.value,
								},
							},
						},
					};
				default:
					return {
						...state,
						Teams: {
							...state.Teams,
							[action.data.character]: {
								...state.Teams[action.data.character],
								[action.data.type]: action.data.value
							},
						},
					};
			}
		}
		case "UPDATE_CHARACTER_EXTRA": {
			switch (action.data.type) {
				case "time":
					return {
						...state,
						Teams: {
							...state.Teams,
							[action.data.character]: {
								...state.Teams[action.data.character],
								[action.data.type]: {
									...state.Teams[action.data.character][action.data.type],
									hasContainer:
										action.data.value > 0
											? true
											: state.Teams[action.data.character][action.data.type].hasContainer,
									coins: action.data.value,
								},
							},
						},
					};
				case "budget":
					return {
						...state,
						Teams: {
							...state.Teams,
							[action.data.character]: {
								...state.Teams[action.data.character],
								[action.data.type]: {
									...state.Teams[action.data.character][action.data.type],
									hasContainer:
										action.data.value > 0
											? true
											: state.Teams[action.data.character][action.data.type].hasContainer,
									coins: state.Teams[action.data.character][action.data.type].coins + action.data.value
								},
							},
						},
					};
				default:
					return {
						...state,
						Teams: {
							...state.Teams,
							[action.data.character]: {
								...state.Teams[action.data.character],
								[action.data.type]: action.data.value
							},
						},
					};
			}
		}
		case "UPDATE_RELATION": {
			return {
				...state,
				Relations: {
					...state.Relations,
					[action.data.key]: action.data.value,
				},
			};
		}
		case "INCREASE_CHARACTER": {
			return {
				...state,
				Teams: {
					...state.Teams,
					[action.data.character]: {
						...state.Teams[action.data.character],
						[action.data.type]: state.Teams[action.data.character][action.data.type] + action.data.value
					}
				}
			}
		}
		case "INCREASE_RELATION": {
			return {
				...state,
				Relations: {
					...state.Relations,
					[action.data.key]: state.Relations[action.data.key] + action.data.value
				}
			}
		}
		case "UPDATE_INVESTED":
			return {
				...state,
				Invested: {
					...state.Invested,
					[action.data.character]: {
						...state.Invested[action.data.character],
						hasContainer: true,
						budget:
							state.Invested[action.data.character].budget +
							(action.data.budget ? action.data.budget : 0),
					},
				},
				Teams: {
					...state.Teams,
					TeamLeader: {
						...state.Teams.TeamLeader,
						budget: {
							...state.Teams.TeamLeader.budget,
							coins:
								state.Teams.TeamLeader.budget.coins -
								(action.data.budget ? action.data.budget : 0),
						},
					},
				},
			};
		case "APPLY_DEFAULT_VALUES": {
			const characters = {};
			const relations = {};
			for (
				let characterIndex = 0;
				characterIndex < action.data.characters.length;
				characterIndex++
			) {
				const character = action.data.characters[characterIndex];
				characters[character.character] = {
					...state.Teams[character.character],
					awarenessOfRole: character.awarenessOfRole ? parseInt(character.awarenessOfRole) : 0,
					belongingness: character.belongingness ? parseInt(character.belongingness) : 0,
					competence: character.competence ? parseInt(character.competence) : 0,
					satisfaction: character.satisfaction ? parseInt(character.satisfaction) : 0,
					time: {
						hasContainer: character.time ? true : false,
						coins: character.time ? parseInt(character.time) : 0
					},
					budget: {
						hasContainer: character.budget ? true : false,
						coins: character.budget ? parseInt(character.budget) : 0
					},
				};
			}
			for (
				let relationIndex = 0;
				relationIndex < action.data.relations.length;
				relationIndex++
			) {
				const relation = action.data.relations[relationIndex];
				relations[relation.relation] = parseInt(relation.value);
			}
			return {
				...state,
				Teams: {
					...state.Teams,
					...characters,
				},
				Relations: {
					...state.Relations,
					...relations,
				},
			};
		}
		case "CLEAR_INVESTED_CONTAINERS":
			const newInvested = {...state.Invested};
			for (const key in newInvested) {
				console.log(key);
				if (key !== 'Marketing') {
					newInvested[key].budget = 0;
					newInvested[key].time = 0;
				}
				console.log(newInvested);
			}
			return {
				...state,
				Invested : newInvested
			};
		case "APPLY_ADDITIONAL_VALUES": {
			const characters = {};
			const relations = {};
			for (let characterIndex = 0; characterIndex < action.data.characters.length; characterIndex++) {
				const character = action.data.characters[characterIndex];
				characters[character.character] = {
					...state.Teams[character.character],
					awarenessOfRole: state.Teams[character.character].awarenessOfRole + parseInt(character.awarenessOfRole),
					belongingness: state.Teams[character.character].belongingness +  parseInt(character.belongingness),
					competence: state.Teams[character.character].competence + parseInt(character.competence),
					satisfaction: state.Teams[character.character].satisfaction + parseInt(character.satisfaction)
				};
			}
			for (let relationIndex = 0; relationIndex < action.data.relations.length; relationIndex++) {
				const relation = action.data.relations[relationIndex];
				relations[relation.relation] = state.Relations[relation.relation] + parseInt(relation.value);
			}
			return {
				...state,
				Teams: {
					...state.Teams,
					...characters,
				},
				Relations: {
					...state.Relations,
					...relations,
				},
			};
		}
		// eslint-disable-next-line no-lone-blocks
		case "UPDATE_INVESTED_AREA": {
			console.log("baord");
			return {
				...state , 
				Invested : action.data
			}
		}
		break;
		default:
			break;
	}
	return state;
};

export default reducer;
