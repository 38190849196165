import React, { useEffect, useState } from "react";

// Imgs
import { ReactComponent as Arrow } from "../../assets/SVG/arrow.svg";
import Translate from "../../utils/Translate";

const _ImpactRow = ({ option, character }) => {

	const [characterValue, setCharacterValue] = useState(0)

	useEffect(() => {
		if (option[character]) {
			setCharacterValue(option[character])
		}
	}, [option, character])

	return (
		<React.Fragment>
			{
				(characterValue !== 0) ?
					<div>
						
						<span style={{ textTransform: "capitalize" }}> <Translate alias="events and colors" word={character}/></span>
						<span className={`${ characterValue > 0 ? "positive" : "negative" }`} >
							<Arrow className={`arrow ${ characterValue > 0 ? "up" : "" }`} />
							{ `${ characterValue > 0 ? "+" : "" } ${ characterValue }`}
						</span>
					</div>
					:
					null
			}
		</React.Fragment>
	)
}

export default _ImpactRow