const urls = {
	backendURL: process.env.REACT_APP_BACKEND_URL,
	socketEndpoint: process.env.REACT_APP_SOCKET_URL,
	authenticate: process.env.REACT_APP_BACKEND_URL + "api/player/login",
	logout: process.env.REACT_APP_BACKEND_URL + "api/authenticate",
	renameTeam: process.env.REACT_APP_BACKEND_URL + "api/team/rename",
	activeCounter: process.env.REACT_APP_BACKEND_URL + "api/counter/active",
	eventLink: process.env.REACT_APP_BACKEND_URL + "api/simteam/active/event",
	fetchEvent:
		process.env.REACT_APP_BACKEND_URL + "simteam/common-event/active",
	groupLink:
		process.env.REACT_APP_BACKEND_URL + "api/active/customer-card-group",
	characterProfiles:
		process.env.REACT_APP_BACKEND_URL + "api/simteam/character/profiles",
	eventPageTranslate:
		process.env.REACT_APP_BACKEND_URL + "api/simteam/event-page-translate",
};

export default urls;
