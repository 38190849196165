import React from "react";

// Imgs
import { ReactComponent as Arrow } from "../../assets/SVG/arrow.svg";
import Translate from "../../utils/Translate";

const _MoneyInvestRow = ({ option }) => {

	return (
		<div>
			{
				option.SimteamEventOptionMoneyInvests.map((money, index) => (
					<div key={ index }>
						<span style={{ textTransform: "capitalize" }}>
						<Translate alias="events and colors" word={money.character}/>
						  </span>
						<span className="negative">
							<Arrow className="arrow" /> { money.amount }
						</span>
					</div>
				)
			)}
		</div>
	)
}

export default _MoneyInvestRow