const TeamPosition = {
	TeamLeaderBlue: {
		angle: "320",
		beforeBG: "#005B96",
		afterBG: "#899196",
		translate: "-1",
		top: "225",
		right: "295"
	},
	TeamLeaderRed: {
		angle: "188",
		afterBG: "#899196",
		beforeBG: "#D01349",
		translate: "1",
		translateB4: "1",
		afterH8: "120",
		top: "620",
		right: "100"
	},
	TeamLeaderGreen: {
		angle: "259",
		afterBG: "#899196",
		beforeBG: "#00a94f",
		translate: "23",
		translateB4: "24",
		top: "585",
		right: "471"
	},
	TeamLeaderYellow: {
		angle: "240",
		afterBG: "#899196",
		beforeBG: "#FCB813",
		beforeH8: "370",
		translate: "23",
		translateB4: "24",
		afterH8: "530",
		top: "812",
		right: "603"
	},
	TeamLeaderWhite: {
		angle: "286",
		afterBG: "#899196",
		beforeBG: "#BFC5C9",
		beforeH8: "400",
		translate: "0",
		top: "395",
		right: "450"
	},
	BlueRed: {
		angle: "161",
		afterBG: "#005B96",
		beforeBG: "#D01349",
		translate: "0",
		beforeH8: "480",
		afterH8: "160",
		top: "320",
		right: "382"
	},
	BlueGreen: {
		angle: "10",
		beforeBG: "#005B96",
		afterBG: "#00a94f",
		translate: "-1",
		beforeH8: "100",
		top: "281",
		right: "577"
	},
	BlueYellow: {
		angle: "212",
		afterBG: "#005B96",
		beforeBG: "#FCB813",
		translate: "-3",
		translateB4: "2",
		beforeH8: "400",
		afterH8: "400",
		top: "492",
		right: "827"
	},
	BlueWhite: {
		angle: "255",
		afterBG: "#005B96",
		beforeBG: "#BFC5C9",
		translate: "-1",
		top: "125",
		right: "729"
	},
	RedGreen: {
		angle: "298",
		afterBG: "#D01349",
		beforeBG: "#00a94f",
		translate: "8",
		translateB4: "7",
		top: "770",
		right: "392"
	},
	RedYellow: {
		angle: "262",
		afterBG: "#D01349",
		beforeBG: "#FCB813",
		translate: "4",
		translateB4: "5",
		beforeH8: "300",
		afterH8: "460",
		top: "960",
		right: "690"
	},
	RedWhite: {
		angle: "310",
		afterBG: "#D01349",
		beforeBG: "#BFC5C9",
		translate: "13",
		translateB4: "11",
		beforeH8: "413",
		afterH8: "473",
		top: "455",
		right: "529"
	},
	GreenYellow: {
		angle: "233",
		afterBG: "#00a94f",
		beforeBG: "#FCB813",
		translate: "0",
		top: "749",
		right: "782"
	},
	GreenWhite: {
		angle: "326",
		afterBG: "#00a94f",
		beforeBG: "#BFC5C9",
		translate: "-5",
		translateB4: "-6",
		top: "442",
		afterH8: "120",
		right: "721"
	},
	YellowWhite: {
		angle: "13",
		afterBG: "#FCB813",
		beforeBG: "#BFC5C9",
		translate: "1",
		afterH8: "270",
		beforeH8: "250",
		top: "556",
		right: "991"
	}
}

export default TeamPosition;
