import React, { useState, useEffect } from "react";

// CSS
import "../assets/css/GroupCard.css";
import Translate from "../utils/Translate";
import { Input, Row } from "reactstrap";
import RealtimeConnection from "../utils/RealtimeConnection";
import { useSelector } from "react-redux";
export default function GroupCard({ activeCustomerCardIds, customerCards }) {
	const [groupData, setGroupData] = useState(undefined);
	const roundSelectedCustomers = useSelector(
		(state) => state.session.roundSelectedCustomers
	);
	const selectedCustomerIds = roundSelectedCustomers.map(
		(customer) => customer.profile.id
	);
	const teamId = window.teamId;

	const selectCardHandler = (event, card) => {
		const isSelectedByMyTeam = roundSelectedCustomers.filter(
			(selection) => {
				return (
					selection.teamId === teamId &&
					selection.profile.id === card.id
				);
			}
		);
		if (
			!selectedCustomerIds.includes(card.id) ||
			selectedCustomerIds.includes(card.id) && isSelectedByMyTeam.length
		) {
			RealtimeConnection.selectProfile({
				profile: card,
				removed: !event.target.checked,
			});
		}
	};

	useEffect(() => {
		if (customerCards) {
			let groupData = customerCards.filter((card) =>
				activeCustomerCardIds.includes(card.id)
			);
			groupData.sort((cardA, cardB) => {
				if (cardA.sort > cardB.sort) {
					return 1;
				} else if (cardA.sort < cardB.sort) {
					return -1;
				} else {
					return 0;
				}
			});
			setGroupData(groupData);
		}
	}, [activeCustomerCardIds, customerCards]);

	return (
		<div className="customer-cards">
			{groupData &&
				groupData.map((group, index) => (
					<div key={index}>
						<div
							style={{
								width: "32%",
								fontSize: "20px",
								overflowWrap: "anywhere",
							}}
						>
							<span>
								<Translate alias="Board" word="Customer Card" />
							</span>
							<span
								style={{ color: "#fdf854", marginLeft: "20px" }}
							>
								{index + 1}
							</span>
						</div>

						<div
							style={{
								border: "1px solid white",
								height: "100%",
								margin: "0 10px",
							}}
						/>
						<div className="customerCardValueContainer">
							<div className="d-grid customer-card-value-row">
								<span>
									<Translate
										alias="Board"
										word="Prerequisites"
									/>
									:
								</span>
								<span style={{ flex: 1, textAlign: "end" }}>
									{group.SimteamCustomerCardTranslation
										? group.SimteamCustomerCardTranslation
												.prerequisites
										: group.prerequisites}
								</span>
							</div>
							<div className="d-grid customer-card-value-row">
								<span style={{ flex: 1 }}>
									<Translate
										alias="Board"
										word="Time Needed"
									/>
									:
								</span>
								<span style={{ flex: 1, textAlign: "end" }}>
									{group.time_needed}
								</span>
							</div>
							<div
								className="d-grid customer-card-value-row"
								style={{
									color: "#fdf854",
								}}
							>
								<span style={{ flex: 1 }}>
									<Translate alias="Board" word="Return" />:
								</span>
								<span style={{ textAlign: "end" }}>
									{group.return}
								</span>
							</div>
							<div
								className="d-grid mt-1 customer-card-value-row"
								style={{
									color: "#fdf854",
								}}
							>
								<span style={{ flex: 1 , opacity: 0 }}>
									<Translate alias="Board" word="" />:
								</span>
								<span style={{ textAlign: "end" }}>
									<Input
										type="checkbox"
										checked={selectedCustomerIds.includes(
											group.id
										)}
										onChange={(e) => {
											selectCardHandler(e, group);
										}}
										style={{ marginLeft: -13 }}
									/>
								</span>
							</div>
						</div>
					</div>
				))}
			{/*groupData && groupData.map((group, index) => (
					<div>
						<div style={{ width: "32%", fontSize: "20px" }}>
							<span>
								<Translate alias="Board" word="Customer Card" />
							</span>
							<span style={{ color: "#fdf854", marginLeft: "20px" }}>
								{index + 1}
							</span>
						</div>

						<div
							style={{
								border: "1px solid white",
								height: "100%",
								margin: "0 10px",
							}}
						/>
						<div className="customerCardValueContainer">
							<div className="d-grid customer-card-value-row">
								<span>
									<Translate
										alias="Board"
										word="Prerequisites"
									/>
									:
								</span>
								<span style={{ flex: 1, textAlign: "end" }}>{group.prerequisites}</span>
							</div>
							<div className="d-grid customer-card-value-row">
								<span style={{ flex: 1 }}>
									<Translate
										alias="Board"
										word="Time Needed"
									/>
									:
								</span>
								<span style={{ flex: 1, textAlign: "end" }}>
									{group.time_needed}
								</span>
							</div>
							<div
								className="d-grid customer-card-value-row"
								style={{
									color: "#fdf854"
								}}
							>
								<span style={{ flex: 1 }}>
									<Translate alias="Board" word="Return" />:
								</span>
								<span style={{ textAlign: "end" }}>
									{group.return}
								</span>
							</div>
						</div>
					</div>
				))*/}
		</div>
	);
}
