import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";

// Helpers
import ITEMS from "../../utils/items";
import RealtimeConnection from "../../utils/RealtimeConnection";

// Images
import { ReactComponent as Time } from "../../assets/SVG/time.svg";
import { ReactComponent as MoneySign } from "../../assets/SVG/moneySign.svg";

// Components
import DraggableContainer from "../Draggables/DraggableContainer";
import { Button } from "react-bootstrap";

const DroppableField = ({
	canEdit,
	alias,
	name,
	type,
	data,
	teamContainer,
	timeCoinsImpact,
	dispatch,
	moneyCoinsImpact,
}) => {
	//const [show, setShow] = useState(false);
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: ITEMS.container,
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
			containerId: monitor.getHandlerId(),
		}),
		drop: (item, monitor) => {
			if (monitor.getHandlerId() !== item.containerId) {
				if (item.type === "Container") {
					RealtimeConnection.addContainer({
						alias: alias,
						name: name,
						type: type,
						coins: 0,
					});
	
					return { success: true };
				}
				if (item.name === "General" && name === "TeamLeader" && item.type === "budget") {
					RealtimeConnection.addContainer({
						alias: alias,
						name: name,
						type: type,
						coins: item.coins ? item.coins : item.budget,
					});
	
					return { success: true };
				}else{
					RealtimeConnection.addContainer({
						alias: alias,
						name: name,
						type: type,
						coins: item.coins ? item.coins : item.time,
					});
	
					return { success: true };
				}
			}
			/*if (item.name === "TeamLeader" && type === "budget") {
				//return {};
			}
			if (name === "TeamLeader" && item.type === "budget" && item.name !== "General") {
				//return {};
			}*/
		},
	});


	//useEffect(() => {
		const characterName = name.charAt(0).toLowerCase() + name.slice(1);
		let show = false;
		let impactObj = undefined
		let impactEventId = undefined
		let impactIndexValue = undefined
		if (timeCoinsImpact) {
			const eventsKeys = Object.keys(timeCoinsImpact)
			if (eventsKeys.length > 0) {
				for (let eventIndex = 0; eventIndex < eventsKeys.length; eventIndex++) {
					const eventTimeImpacts = timeCoinsImpact[eventsKeys[eventIndex]]
					for (let impactIndex = 0; impactIndex < eventTimeImpacts.length; impactIndex++) {
						const impact = eventTimeImpacts[impactIndex]
						if (!impact.status) {
							if (impact.impact[characterName]) {
								impactEventId = eventsKeys[eventIndex]
								impactIndexValue = impactIndex
								impactObj = impact.impact
								show = true;
							}
							break
						}
					}
				}
			}
		}


		let moneyShow = false;
		let moneyImpactObj = undefined;
		let moneyImpactEventId = undefined;
		let moneyImpactIndexValue = undefined;
		if (moneyCoinsImpact) {
			const eventsMoneyKeys = Object.keys(moneyCoinsImpact);
			if (eventsMoneyKeys.length > 0) {
				for (
					let eventIndex = 0;
					eventIndex < eventsMoneyKeys.length;
					eventIndex++
				) {
					const eventTimeImpacts =
						moneyCoinsImpact[eventsMoneyKeys[eventIndex]];
					for (
						let impactIndex = 0;
						impactIndex < eventTimeImpacts.length;
						impactIndex++
					) {
						const impact = eventTimeImpacts[impactIndex];
						if (!impact.status) {
							if (impact.impact[characterName]) {
								moneyImpactEventId = eventsMoneyKeys[eventIndex];
								moneyImpactIndexValue = impactIndex;
								moneyImpactObj = impact.impact;
								moneyShow = true;
							}
							break;
						}
					}
				}
			}
		}
		if(type == 'budget'){
			console.log(data.coins);
			console.log(moneyImpactObj);

		}
	
	//}, [timeCoinsImpact])

	//useEffect(() => {
	/*if (timeCoinsImpact && Object.keys(timeCoinsImpact)[0]) {
		const el = Object.keys(timeCoinsImpact)[0];

		if (el.toLowerCase() === name.toLowerCase()) {
			show = true;
		}
	}*/
	//}, [timeCoinsImpact]);

	const handleNext = (e) => {
		e.stopPropagation();
		RealtimeConnection.nextTimeImpact({ 
			name: name.toLowerCase(), 
			eventId: impactEventId, 
			impactIndex: impactIndexValue 
		});
	};

	const justTranslate = function(word , alias)
	{
		let translatedWord = word

		if (window.staticWords) {
			if (window.staticWords[alias]) {
				if (window.staticWords[alias][word]) {
					translatedWord = window.staticWords[alias][word]
				}
			}
	}
	return translatedWord
	}
	const handleMoneyNext = (e) => {
		e.stopPropagation();
		RealtimeConnection.nextMoneyImpact({
			name: name.toLowerCase(),
			eventId: moneyImpactEventId,
			impactIndex: moneyImpactIndexValue,
		});
	};

	const playerCanDrop = canEdit && !data.hasContainer ? drop : null;
	const backgroundColor = isOver && canDrop && canEdit ? "#CCCCCC" : "";
	return (
		<React.Fragment>
			<div
				ref={playerCanDrop}
				style={{ backgroundColor: backgroundColor }}
			>
				{type === "time" && (
					<React.Fragment>
						{impactObj && show &&
							<div className="react-tiny-popover-container">
								<div>
									<p>
										{justTranslate('move','events and colors')}{" "}{ Math.abs(impactObj[characterName]) }{" "}{justTranslate('time coins','events and colors')}{" "}
										{justTranslate('out of','events and colors')}{" "} {justTranslate(name.toLowerCase(),'events and colors')}{" "} {justTranslate('container to invested','events and colors')}
									</p>
								</div>

								{
									(window.adminId || (parseInt(data.coins) - parseInt(impactObj.currentTime) === parseInt(impactObj[characterName]))) ?
										<Button
											className="nextBtnContainer"
											size="sm"
											onClick={(e) => handleNext(e)}
										>
											Next
										</Button>
										:
										null
								}
							</div>
						}

						{data.hasContainer ? (
							<DraggableContainer
								canEdit={canEdit}
								coins={data.coins}
								isHorizontal={!teamContainer}
								alias={alias}
								name={name}
								type={type}
							/>
						) : (
							<div className={`svgContainer ${name}`}>
								<Time />
							</div>
						)}
					</React.Fragment>
				)}
				{moneyImpactObj && type == 'budget' && moneyShow && (
					<div className="react-tiny-popover-container-money">
						<div>
							<p>
								{justTranslate('move','events and colors')}{" "} {Math.abs(moneyImpactObj[characterName])} {" "}
								{justTranslate('money coins','events and colors')}{" "}
								{justTranslate('out of','events and colors')}{" "}
								{justTranslate(name.toLowerCase(),'events and colors')}{" "} {justTranslate('container to invested','events and colors')}
							</p>
						</div>

						{parseInt(moneyImpactObj[characterName]) > 0 ? (
							<React.Fragment>
								{window.adminId ||
								parseInt(data.coins) + parseInt(moneyImpactObj[characterName]) ==
									parseInt(moneyImpactObj.currentBudget) ? (
									<Button
										className="nextBtnContainer"
										size="sm"
										onClick={(e) => handleMoneyNext(e)}
									>
										Next
									</Button>
								) : null}
							</React.Fragment>
						) : (
							<React.Fragment>
								{window.adminId ||
								parseInt(data.coins) - parseInt(moneyImpactObj[characterName]) ==
								parseInt(moneyImpactObj.currentBudget) ? (
									<Button
										className="nextBtnContainer"
										size="sm"
										onClick={(e) => handleMoneyNext(e)}
									>
										Next
									</Button>
								) : null}
							</React.Fragment>
						)}
					</div>
				)}
				{type === "budget" && (					
					<React.Fragment>
						{data.hasContainer ? (
							<DraggableContainer
								canEdit={canEdit}
								coins={data.coins}
								isHorizontal={!teamContainer}
								alias={alias}
								name={name}
								type={type}
							/>
						) : (
							<div className={`svgContainer ${name}`}>
								<MoneySign />
							</div>
						)}
					</React.Fragment>
				)}
			</div>
		</React.Fragment>
	);
	{
		/*<React.Fragment>
		<img src={ teamContainer ? containerV : container } alt="container" width="100%" height="100%" />
		<div className={ teamContainer ? "Coins-vertical-droppable" : "Coins-droppable" }>
			<span className="helper" />
		</div>
	</React.Fragment>*/
	}
};

export default DroppableField;
