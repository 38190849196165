const sessionState = {
	roundNumber: 0,
	team: 0,
	score: {},
	amount: 0,
	files: [],
	timeCoinsImpact: {},
	moneyCoinsImpact: {},
	teamName: "",
	currentEventId: undefined,
	lastActiveEventId: undefined,
	viewLastActiveEvent: false,
	customerCards: [],
	activeCustomerCardIds: undefined,
	currentEvent: {},
	selectedEvents: {},
	eventsCurrentView: "",
	selectedEventOption: {},
	probabilityCard: {},
	eventOptionCards: [],
	selectedEventCard: undefined,
	selectedCustomers :[] ,
	teamAlreadySelected:false,
	teamsThatSelected:[],
	eventSectionStatus: {},
	/*eventCardAppearance: {
		allowOptionSelection: false,
		showTimeCoins: false,
		showMoneyCoins: false,
		showProbability: false,
		showImpact: false,
	},*/
	eventSlideAppearance: {
		enabled: false,
		slide: {},
	},
	localizations: {},
	charactersProfile: {},
	messages: [],
	customProbabilityCards: {
		status: false,
		probabilityValue: 0,
		selectedCards: [],
		cards: []
	},
	applyImpact: undefined,
	counter: undefined,
	fetchEventPageTranslation: undefined,
	centralBoardData: [],
	roundSelectedCustomers : [],
	sessionCustomersHistory: [],
	teamSelectedEventOption:false,
};

export default sessionState;
